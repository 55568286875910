<template>
    <DefaultPaymentConfig @submitSetting="handleSubmit">
        <component :is="paymentConfigComponent" v-if="paymentConfigComponent" />
    </DefaultPaymentConfig>
</template>

<script>
import { ref, provide, computed } from 'vue';
import { useApi } from '@js/utils/api';
import useAlert from '@js/composables/useAlert.js';
import { trans } from 'laravel-vue-i18n';
import DefaultPaymentConfig from '@js/components/payments/DefaultPaymentConfig.vue';
import TransferPaymentConfig from '@js/components/payments/TransferPaymentConfig.vue';
import AfterpayPaymentConfig from '@js/components/payments/AfterpayPaymentConfig.vue';
import BillinkPaymentConfig from '@js/components/payments/BillinkPaymentConfig.vue';
import GiftcardPaymentConfig from '@js/components/payments/GiftcardPaymentConfig.vue';
import In3PaymentConfig from '@js/components/payments/In3PaymentConfig.vue';
import PaypalPaymentConfig from '@js/components/payments/PaypalPaymentConfig.vue';
import PayperemailPaymentConfig from '@js/components/payments/PayperemailPaymentConfig.vue';

export default {
    props: ['paymentMethod'],
    components: {
        DefaultPaymentConfig,
    },
    setup(props, { emit }) {
        const paymentMethod = ref(props.paymentMethod);
        const { put, data } = useApi(`payment/${props.paymentMethod.name}/settings`);
        const { success, error: errorAlert } = useAlert();

        const paymentConfigComponentsMap = {
            afterpaynew: AfterpayPaymentConfig,
            transfer: TransferPaymentConfig,
            billink: BillinkPaymentConfig,
            giftcard: GiftcardPaymentConfig,
            capayable: In3PaymentConfig,
            paypal: PaypalPaymentConfig,
            payperemail: PayperemailPaymentConfig,
        };

        provide('paymentMethod', paymentMethod);

        const handleSubmit = () => {
            put(paymentMethod.value.configs)
                .then(() => {
                    if (data.value?.status) {
                        success(trans('alerts.settings_updated'));
                    }
                })
                .catch(() => {
                    if (error?.value?.response?.data?.message) return errorAlert(error.value.response.data.message);
                    errorAlert(trans('alerts.unexpected_error'));
                });
        };

        const paymentConfigComponent = computed(() => {
            return paymentConfigComponentsMap[props.paymentMethod.name] || null;
        });

        return {
            handleSubmit,
            paymentConfigComponent,
        };
    },
};
</script>
