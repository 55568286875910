<template>
    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.allowed_giftcard_methods') }}</label>
        <SelectWithMultipleOptions
            v-model="paymentMethod.configs.allowed_methods"
            :options="ALLOWED_GIFTCARD_METHODS"
        />
    </div>
    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.add_giftcard_method_list') }}</label>
        <textarea v-model="extraMethods" class="w-full p-2 border rounded text-sm input-primary"></textarea>
        <p class="text-xs text-gray-700">{{ $t('payments.add_giftcard_method_list_description') }}</p>
    </div>
</template>

<script setup>
import { inject, watch, ref } from 'vue';
import SelectWithMultipleOptions from '@js/components/SelectWithMultipleOptions.vue';
import { ALLOWED_GIFTCARD_METHODS } from '@js/utils/constants.js';

const paymentMethod = inject('paymentMethod');

const extraMethods = ref(
    paymentMethod.value.configs.extra_methods.length > 0 ? paymentMethod.value.configs.extra_methods.join() : ''
);

watch(extraMethods, value => {
    paymentMethod.value.configs.extra_methods = value.split(',');
});
</script>
