<template>
    <div class="p-5 flex gap-4 xl:flex-row flex-col h-full">
        <div class="xl:order-1 order-2 divide-y bg-white rounded-2xl w-full h-full">
            <div class="rounded-t-2xl flex items-center justify-between p-5 sticky top-0 bg-white gap-8">
                <div class="space-y-1">
                    <h1 class="text-2xl font-bold">{{ $t('dashboard.order_payment_methods') }}</h1>
                    <p class="text-gray-500 text-sm">{{ $t('dashboard.order_payment_methods_description') }}</p>
                </div>
                <div>
                    <button class="btn-primary px-6 py-3" @click="handleSave">{{ $t('config.save') }}</button>
                </div>
            </div>

            <loading class="pb-4 pt-4" v-if="paymentMethodsLoading || orderUpdateLoading" />

            <div class="flex gap-8 flex-col md:pr-11 md:pl-9 py-8 px-7 h-full">
                <div class="flex gap-7 md:flex-row flex-col-reverse">
                    <div class="flex md:w-1/2 w-full">
                        <input
                            type="text"
                            :placeholder="$t('dashboard.search_countries')"
                            v-model="query"
                            class="input-primary"
                        />
                    </div>

                    <div v-if="paymentsOrder?.length" class="flex items-center md:w-1/2 w-full gap-2">
                        <img
                            v-if="selectedCountry.key !== 'all'"
                            :src="getFlag(selectedCountry.key)"
                            class="w-6 h-6"
                            :alt="selectedCountry.value"
                        />
                        <i v-else class="fa-solid fa-location-dot w-6"></i>
                        <p class="base-xl text-black">{{ selectedCountry.value }}</p>
                    </div>
                </div>

                <div v-if="paymentsOrder?.length" class="flex gap-7 md:flex-row flex-col">
                    <div
                        class="rounded-lg border border-warmGrey flex flex-col max-h-screen overflow-y-auto md:w-1/2 w-full"
                    >
                        <Sortable
                            :list="paymentsOrder"
                            item-key="id"
                            tag="div"
                            @update="handleOrderUpdate"
                            class="divide-y"
                        >
                            <template #item="{ element }">
                                <div
                                    class="draggable bg-white p-6 first:rounded-t-lg last:rounded-b-lg last:border-b"
                                    :key="element.name"
                                    :data-name="element.name"
                                >
                                    <div class="flex items-center gap-2">
                                        <img
                                            :src="getPaymentImagePath(element.icon)"
                                            class="w-10 h-8"
                                            :alt="element.title"
                                        />
                                        <p>{{ element.title }}</p>
                                    </div>
                                </div>
                            </template>
                        </Sortable>
                    </div>
                    <div
                        class="rounded-lg border border-warmGrey flex flex-col max-h-screen overflow-y-auto divide-y md:w-1/2 w-full"
                    >
                        <button
                            @click="fetchOrderByCountry({ key: 'all', value: $t('dashboard.all_countries') })"
                            class="p-6 duration-300 transition-all hover:bg-buckarooGreen"
                            :class="{ '!bg-buckarooGreen': selectedCountry.key.toLowerCase() === 'all' }"
                        >
                            <div class="flex gap-5 items-center">
                                <i class="fa-solid fa-location-dot w-6"></i>
                                {{ $t('dashboard.all_countries') }}
                            </div>
                        </button>

                        <button
                            @click="fetchOrderByCountry(country)"
                            class="p-6 last:rounded-b-lg last:border-b base duration-300 transition-all hover:bg-buckarooGreen"
                            :key="country.value"
                            v-for="country in filteredCountries"
                            v-bind:class="{
                                '!bg-buckarooGreen': selectedCountry.key.toLowerCase() === country.key.toLowerCase(),
                            }"
                        >
                            <div class="flex gap-5 items-center">
                                <img :src="getFlag(country.key)" class="w-6" :alt="country.value" />
                                <span class="block">{{ country.value }}</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div v-else>
                    <p>{{ $t('dashboard.no_payment_methods_enabled') }}</p>
                </div>
            </div>
        </div>
        <div
            class="xl:order-2 order-1 h-fit bg-white xl:max-w-[450px] w-full md:pl-8 md:pb-9 md:pr-11 md:pt-7 px-7 py-8 rounded-lg space-y-5"
        >
            <h2 class="font-bold text-xl">{{ $t('dashboard.order_description') }}</h2>
            <div class="flex flex-col gap-8">
                <div class="flex gap-2.5 items-start">
                    <ListRowNumber content="1" />
                    <span v-html="$t('dashboard.order_step_one')"></span>
                </div>
                <div class="flex gap-2.5 items-start">
                    <ListRowNumber content="2" />
                    <span v-html="$t('dashboard.order_step_two')"></span>
                </div>
                <div class="flex gap-2.5 items-start">
                    <ListRowNumber content="3" />
                    <span v-html="$t('dashboard.order_step_three')"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useCountries } from '@js/utils/countries';
import { useApi } from '@js/utils/api';
import useAlert from '@js/composables/useAlert';
import { ref, computed } from 'vue';
import { wTrans, trans } from 'laravel-vue-i18n';
import getFlag from '@js/utils/get-flag.js';
import { Sortable } from 'sortablejs-vue3';
import { getPaymentImagePath } from '@js/utils/get-image-url.js';

export default {
    props: [],
    components: {
        Sortable,
    },
    setup(props, { emit }) {
        const { countries } = useCountries();
        const query = ref(null);
        const paymentsOrder = ref(null);
        const selectedCountry = ref({ key: 'all', value: wTrans('dashboard.all_countries') });
        const { get, data, error: paymentMethodsError, setEndpoint } = useApi();
        const { success, error } = useAlert();

        const fetchOrderByCountry = country => {
            setEndpoint(`/payments/order/${country.key}`);

            get()
                .then(() => {
                    if (data.value.status) {
                        selectedCountry.value = country;
                        paymentsOrder.value = data.value.payments_order;
                    }
                })
                .catch(() => {
                    error(paymentMethodsError?.value.response?.data?.message);
                });
        };

        const handleOrderUpdate = event => {
            const updatedList = event.to.children;
            const newOrder = Array.from(updatedList).map(child => child.dataset.name);

            paymentsOrder.value = paymentsOrder.value.sort(
                (a, b) => newOrder.indexOf(a.name) - newOrder.indexOf(b.name)
            );
        };

        const handleSave = () => {
            const { put, error: updateOrderError } = useApi('/settings');

            put({
                [`${selectedCountry.value.key.toLowerCase()}_payment_order`]: paymentsOrder.value.map(
                    paymentMethod => paymentMethod.name
                ),
            })
                .then(() => {
                    success(trans('dashboard.order_updated'));
                })
                .catch(() => {
                    error(updateOrderError.response?.data?.message);
                });
        };

        const filteredCountries = computed(() => {
            if (query.value) {
                return countries.value.filter(country => {
                    const normalizedCountry = country.value.toLowerCase().trim();
                    const normalizedValue = query.value.toLowerCase().trim();
                    return normalizedCountry.includes(normalizedValue);
                });
            }

            return countries.value;
        });

        fetchOrderByCountry(selectedCountry.value);

        return {
            fetchOrderByCountry,
            selectedCountry,
            filteredCountries,
            query,
            getFlag,
            handleOrderUpdate,
            handleSave,
            data,
            paymentsOrder,
            getPaymentImagePath,
        };
    },
};
</script>
