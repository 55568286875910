<template>
    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.date_due') }}</label>

        <input
            type="number"
            class="input-primary bg-gray-100"
            :placeholder="$t('payments.date_due')"
            v-model="paymentMethod.configs.date_due"
        />
    </div>

    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.allowed_payment_methods') }}</label>
        <SelectWithMultipleOptions v-model="paymentMethod.configs.allowed_methods" :options="paymentOptions" />
    </div>

    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.merchant_sends_email') }}</label>

        <select class="input-primary bg-gray-100" v-model="paymentMethod.configs.merchant_sends_email">
            <option :value="1">{{ $t('dashboard.yes') }}</option>
            <option :value="0">{{ $t('dashboard.no') }}</option>
        </select>
        <p class="text-xs text-gray-700">{{ $t('payments.merchant_sends_email_description') }}</p>
    </div>
</template>

<script>
import { inject } from 'vue';
import SelectWithMultipleOptions from '../SelectWithMultipleOptions.vue';

export default {
    props: [],
    components: { SelectWithMultipleOptions },
    setup(props, { emit }) {
        const paymentMethod = inject('paymentMethod');

        const paymentOptions = [
            { key: 'alipay', value: 'alipay' },
            { key: 'amex', value: 'amex' },
            { key: 'belfius', value: 'belfius' },
            { key: 'applepay', value: 'applepay' },
            { key: 'bancontactmrcash', value: 'bancontactmrcash' },
            { key: 'cartebleuevisa', value: 'cartebleuevisa' },
            { key: 'eps', value: 'eps' },
            { key: 'gjiropay', value: 'gjiropay' },
            { key: 'ideal', value: 'ideal' },
            { key: 'kbc', value: 'kbc' },
            { key: 'maestro', value: 'maestro' },
            { key: 'mastercard', value: 'mastercard' },
            { key: 'nexi', value: 'nexi' },
            { key: 'p24', value: 'przelewy24' },
            { key: 'payconiq', value: 'payconiq' },
            { key: 'paypal', value: 'paypal' },
            { key: 'postepay', value: 'postepay' },
            { key: 'transfer', value: 'bank transfer' },
            { key: 'visa', value: 'visa' },
            { key: 'vpay', value: 'vpay' },
            { key: 'wechatpay', value: 'wechatpay' },
        ];

        return {
            paymentMethod,
            paymentOptions,
            SelectWithMultipleOptions,
        };
    },
};
</script>
